@import "styles/utils/mixins";

.wrapper {
  padding: 85px 0;
}

.header {
  display: grid;
  grid-template-columns: 8fr 4fr;
  margin-bottom: 72px;
  grid-gap: 15px;
  align-items: flex-end;
}

.title {
  font-size: 60px;
  line-height: 68px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: #18162c;
}

@include small-screen {
  .wrapper {
    padding: 65px 0;
  }
}
