@import "styles/utils/mixins";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .item {
    width: 33.33%;
    flex: 1 1 33.33%;
    border-top: 1px solid #dfe0e7;
    border-right: 1px solid #dfe0e7;
    border-bottom: 1px solid #dfe0e7;

    &:last-child,
    &:nth-child(3n) {
      border-right: none;
    }
  }
}

.single {
  flex: 0 1 50.5%;

  &:last-child {
    border-right: 1px solid #dfe0e7;
  }
}

.header {
  display: grid;
  grid-template-columns: 8fr 4fr;
  margin-bottom: 72px;
  grid-gap: 15px;
  align-items: flex-end;
}

.title {
  font-size: 60px;
  line-height: 68px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
}

.tabs-wrapper {
  margin-bottom: 72px;
}

@include small-screen {
  .blocks {
    padding: 0 15px;
  }

  .header {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-bottom: 40px;
  }

  .title {
    font-size: 30px;
    line-height: 38px;
    font-family: "TT Interfaces", sans-serif;
    font-weight: 700;
  }

  .item {
    width: 100%;
    flex: 1 1 100%;
    border-left: 1px solid #dfe0e7;

    &:last-child,
    &:nth-child(3n) {
      border-right: 1px solid #dfe0e7;
    }
  }

  .single {
    flex: 1 1 100%;
  }
}
