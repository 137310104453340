.wrapper {
  margin-top: 50px;
}

.top-content {
  flex-direction: column;
}

.title {
  font-size: 30px;
  line-height: 38px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
}

.description {
  width: 100%;
  margin-bottom: 40px;
}

.top-navigation {
  margin-bottom: 40px;
}

.item {
  flex: 1;
  border-right: 1px solid #dfe0e7;
  border-top: 1px solid #dfe0e7;
  border-bottom: 1px solid #dfe0e7;
}
