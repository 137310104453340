@import "styles/utils/mixins";

.wrapper {
  padding: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.block {
  position: relative;
  text-align: left;
  width: 100%;
}

.logo {
  align-self: flex-end;
  display: block;
  height: 42px;
  margin-bottom: 40px;
  width: auto;
}

.title {
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #18162c;
  margin: 0 0 12px;
  text-align: left;
}

.lead {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 34px;
  color: #c8c7cf;
}

.text {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #18162c;
  text-align: left;
}

@include small-screen {
  .wrapper {
    min-height: 160px;
    padding: 31px 22px 28px 31px;
    border: 1px solid #dfe0e7;
    width: 100%;
    flex: 1 1 100%;
    position: relative;
  }

  .logo {
    margin-bottom: 0;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 14px;
  }

  .lead {
    margin: 0 0 19px;
    max-width: 80%;
    position: relative;
  }

  .text {
    font-size: 12px;
    line-height: 20px;
  }

  .icon {
    display: block;
    position: absolute;
    right: 32px;
    top: -6px;
    cursor: pointer;
    width: 9px;
    height: 6px;

    svg {
      transform: rotate(0deg);
      transition: transform 0.3s linear;
    }

    &-open {
      svg {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
      }
    }
  }
}
